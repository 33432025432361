import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faFacebookF, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer mt-5 py-5 bg-light"> {/* Increased vertical padding from py-3 to py-5 */}
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} sm={6} className="text-center">
            <span className="text-muted">© {year} MemeTrade Interactive LLC. All rights reserved.</span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={6} className="text-center">
            <a href="mailto:support@memetrade.app" className="text-muted mx-2">Support Email</a> {/* Added support email */}
            <Link to="/privacy" className="text-muted mx-2">Privacy Policy</Link>
            <p className="text-muted mx-2">3400 Cottage Way, Ste G2 Sacramento, CA 95825</p> {/* Added office address */}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={6} className="text-center">
            <a href="https://twitter.com/memetradeapp" className="text-muted mx-2" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://instagram.com/memetradeapp" className="text-muted mx-2" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://facebook.com/memetradeapp" className="text-muted mx-2" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://discord.gg/M7NByD4PE4" className="text-muted mx-2" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
