import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBVuRYTJrMQUD8Xtza3JC9nqlbXb3Zvz6A",
  authDomain: "memetrade-app.firebaseapp.com",
  projectId: "memetrade-app",
  storageBucket: "memetrade-app.appspot.com",
  messagingSenderId: "870431794929",
  appId: "1:870431794929:web:d168eed6dd48f8af27ab50",
  measurementId: "G-C79W7M3XLP",
};

const app = initializeApp(firebaseConfig, "app");
const db = getFirestore(app);

export function useTicker(memeId) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchMeme = async () => {
      const memeRef = doc(db, "memes", memeId);
      const memeDoc = await getDoc(memeRef);

      if (memeDoc.exists()) {
        const data = memeDoc.data();
        console.log(data);
        setImageUrl(data.imageUrl);
      } else {
        console.error("Meme not found!");
        setImageUrl(null);
      }
    };

    fetchMeme();
  }, [memeId]);

  return { imageUrl };
}
