import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import Landing from "./pages/Landing";
import Privacy from "./pages/Privacy";
import Footer from "./layout/Footer";

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseWebsiteConfig = {
//   apiKey: "AIzaSyCzfukZicz7VwRq5rUPUL59Uf7rnxgBsco",
//   authDomain: "memetrade-website.firebaseapp.com",
//   projectId: "memetrade-website",
//   storageBucket: "memetrade-website.appspot.com",
//   messagingSenderId: "463524262499",
//   appId: "1:463524262499:web:fae9188b341a2cdeb57e56",
//   measurementId: "G-T00111LZ2R"
// };

// Initialize Firebase
// const website = initializeApp(firebaseWebsiteConfig);
// const analytics = getAnalytics(website);

function App() {
  return (
    <Router>
      <div className="App d-flex flex-column" style={{ minHeight: "100vh" }}>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <div>
              <Link className="navbar-brand" to="/">
                <span style={{ fontFamily: "Lobster", fontSize: 26 }}>
                  MemeTrade
                </span>
              </Link>
            </div>
            {/* <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
              <a href="https://web.memetrade.app/" className="btn btn-outline-success m-2" role="button">Launch Web App</a>
            </div> */}
          </div>
        </nav>

        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
