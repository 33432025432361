import React from "react";
import Marquee from "react-fast-marquee";
import { useTicker } from "../../hooks/useTicker";

function StockTicker() {
  let delta0 = 0,
    delta1 = 0,
    delta2 = 0,
    delta3 = 0,
    delta4 = 0,
    delta5 = 0,
    delta6 = 0,
    delta7 = 0,
    delta8 = 0;
  const memeId0 = "02XgnhWy2mnicWZXhiRs"; // DAMNBAM
  const { imageUrl: imageUrl0 } = useTicker(memeId0);

  const memeId1 = "1E6YElvLBrJfPBo1ivbm"; // MY20S
  const { imageUrl: imageUrl1 } = useTicker(memeId1);

  const memeId2 = "1Szj8AX58duzaaROiL21"; // DRUNKET
  const { imageUrl: imageUrl2 } = useTicker(memeId2);

  const memeId3 = "24UbwbGiFSbWaeuIKkOL"; // FROGTOAD
  const { imageUrl: imageUrl3 } = useTicker(memeId3);

  const memeId4 = "3nrJvdFSByOvKNfszUpB"; // GARYLEVR
  const { imageUrl: imageUrl4 } = useTicker(memeId4);

  const memeId5 = "6CQtS2mn52fx5gYEISDm"; // SBFTWT
  const { imageUrl: imageUrl5 } = useTicker(memeId5);

  const memeId6 = "80ByL4O7tSMu6moU8wZU"; // GLMSMIND
  const { imageUrl: imageUrl6 } = useTicker(memeId6);

  const memeId7 = "96XuFouZtg9VGQuQy2ZD"; // NOTHING
  const { imageUrl: imageUrl7 } = useTicker(memeId7);

  const memeId8 = "BUuy4dvhwHb4A45BNGGp"; // 27TODAY
  const { imageUrl: imageUrl8 } = useTicker(memeId8);

  return (
    <Marquee>
      <img src={imageUrl0} height="50" alt="" />
      &nbsp;<span style={{ color: "#37f260" }}>+{delta0.toFixed(2)}%</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      <img src={imageUrl1} height="50" alt="" />
      &nbsp;
      <span style={{ color: "#37f260" }}>+{delta1.toFixed(2)}%</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      <img src={imageUrl2} height="50" alt="" />
      &nbsp;
      <span style={{ color: "#37f260" }}>+{delta2.toFixed(2)}%</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      <img src={imageUrl3} height="50" alt="" />
      &nbsp;
      <span style={{ color: "#37f260" }}>+{delta3.toFixed(2)}%</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      <img src={imageUrl4} height="50" alt="" />
      &nbsp;
      <span style={{ color: "#37f260" }}>+{delta4.toFixed(2)}%</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      <img src={imageUrl5} height="50" alt="" />
      &nbsp;
      <span style={{ color: "#37f260" }}>+{delta5.toFixed(2)}%</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      <img src={imageUrl6} height="50" alt="" />
      &nbsp;
      <span style={{ color: "#37f260" }}>+{delta6.toFixed(2)}%</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      <img src={imageUrl7} height="50" alt="" />
      &nbsp;
      <span style={{ color: "#37f260" }}>+{delta7.toFixed(2)}%</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      <img src={imageUrl8} height="50" alt="" />
      &nbsp;
      <span style={{ color: "#37f260" }}>+{delta8.toFixed(2)}%</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </Marquee>
  );
}

export default StockTicker;
