import mockup from "./mockup.png";
import download from "./testflight-button.png";
import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";
import Ticker from "./Ticker";

export default function Home() {
  return (
    <div>
      <div
        id="landing"
        className="container flex-grow-1 d-flex flex-column justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-6 d-flex justify-content-center align-items-center">
            <div>
              <h1 className="display-1 mt-5" style={{ fontFamily: "Lobster" }}>
                MemeTrade
              </h1>
              <h2>Seize the memes of production.</h2>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://testflight.apple.com/join/wknfh88W"
                className="mt-5"
              >
                <img
                  src={download}
                  className="img-fluid"
                  width="200"
                  alt="Download on the App Store"
                />
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 d-flex justify-content-center align-items-center">
            <div className="text-center">
              <div className="row">
                <div className="col">
                  <img
                    src={mockup}
                    className="img-fluid"
                    width="400"
                    alt="Login Screen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gradient-banner">
        <Ticker />
      </div>

      <div
        id="product"
        className="container flex-grow-1 d-flex flex-column justify-content-center mt-5"
        style={{ minHeight: "100vh" }}
      >
        <h1
          style={{ fontFamily: "Lobster" }}
          className="display-3 text-center mb-4 mt-5"
        >
          How To Play
        </h1>
        <p className="lead text-center">
          Compete against the market to be the top meme trader.*
        </p>
        <small className="text-muted text-center">
          *For entertainment purposes only. Memes do not have any intrinsic
          financial value.
        </small>

        <div className="row my-5 position-relative">
          <div className="col">
            <div className="m-2 p-3">
              <div className="row">
                <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                  <h3 className="display-5" style={{ fontFamily: "Lobster" }}>
                    Browse
                  </h3>
                  <p className="my-4">
                    Swipe left to pass, swipe right to invest. Share with
                    friends and "short-sell" memes.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 text-md-end d-flex flex-column justify-content-center align-items-md-end my-4">
                  <img
                    src={image1}
                    className="img-fluid rounded border"
                    alt="Swiping"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row my-5 position-relative">
          <div className="col">
            <div className="m-2 p-3">
              <div className="row">
                <div className="col-lg-4 col-sm-12 order-last order-md-first col-md-6 text-md-end d-flex flex-column justify-content-center align-items-md-end my-4">
                  <img
                    src={image2}
                    className="img-fluid rounded border"
                    alt="Investing"
                  />
                </div>
                <div className="offset-lg-2 col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                  <h3 className="display-5" style={{ fontFamily: "Lobster" }}>
                    Invest
                  </h3>
                  <p className="my-4">
                    Tip the creator/poster with MEME coins to add the "meme
                    stock" to your portfolio for quick access to share with
                    friends.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row my-5 position-relative">
          <div className="col">
            <div className="m-2 p-3">
              <div className="row">
                <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                  <h3 className="display-5" style={{ fontFamily: "Lobster" }}>
                    Earn
                  </h3>
                  <p className="my-4">
                    Gain XP by posting memes and giving tips. XP moves you up
                    the leaderboard!
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 text-md-end d-flex flex-column justify-content-center align-items-md-end my-4">
                  <img
                    src={image3}
                    className="img-fluid rounded border"
                    alt="Earning"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container flex-grow-1 d-flex flex-column justify-content-center"
        style={{ minHeight: "100vh" }}
        id="faq"
      >
        <h1
          className="text-center mb-5 display-3"
          style={{ fontFamily: "Lobster" }}
        >
          FAQ
        </h1>
        <div className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                How do I get memecoins?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Early adopters start out with 1,000 MEME. In-app purchases
                available (100 memecoins for $1). An XRP/MEME AMM pool is coming
                soon to the XRP Ledger!
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                How do I earn XP?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                You also get 50 XP every time you post! Tipping also earns you 1
                XP.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Can I cash out?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We're working towards allowing users to cash out MEME for XRP
                via the AMM pool.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                What are the user ranks?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                The initial theme of the trading experience is based on a
                national memes exchange based in the Republic of Kekistan.
                However, the MemeTrade Kekistanis are a breakaway political
                group whose primary aim is to replace the original flag which is
                tainted. Other themes are to be added. Ranks are subject to
                change.
                <br />
                <br />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">XP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Meme God</td>
                      <td>(Top Trader)</td>
                    </tr>
                    <tr>
                      <td>Supreme Meme Overlord</td>
                      <td>1,000,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Lord</td>
                      <td>100,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Master</td>
                      <td>10,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Wizard</td>
                      <td>5,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Alchemist</td>
                      <td>3,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Magician</td>
                      <td>2,200 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Conjurer</td>
                      <td>1,500 XP</td>
                    </tr>
                    <tr>
                      <td>Cultist of Kek</td>
                      <td>1,200 XP</td>
                    </tr>
                    <tr>
                      <td>Shitpost Sherrif</td>
                      <td>900 XP</td>
                    </tr>
                    <tr>
                      <td>Shitpost Deputy</td>
                      <td>500 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Factory Foreman</td>
                      <td>250 XP</td>
                    </tr>
                    <tr>
                      <td>Memesmith Journeyman</td>
                      <td>100 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Sweatshop Laborer</td>
                      <td>30 XP</td>
                    </tr>
                    <tr>
                      <td>Kekistan Peasant</td>
                      <td>0 XP</td>
                    </tr>
                    <tr>
                      <td>Prisoner</td>
                      <td>&lt; 0 XP</td>
                    </tr>
                    <tr>
                      <td>Troll</td>
                      <td>&lt;= -100 XP</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                What are the supply dynamics of MEME?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                MEME is a payment token, and MemeTrade has full monetary policy
                to ensure an enjoyable experience for all. If you're interested
                in a limited supply token, we're also launching $DOG on the XRP
                Ledger. More info coming soon.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                What is the creator incentive?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Post creators get 50 XP and earn tips paid in MEME. If the
                poster isn't the original copyright holder of the meme, some of
                the tips will be set aside for the original creator to claim.
                MemeTrade's process for copyright holder verification requires
                the claimant to meet a basic burden of proof.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                What is the trustline for MEME?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Go{" "}
                <a
                  href="https://t.co/3r4qz8I8fb"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                to create a trustline.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
